// components/Footer.js
import React from 'react';
import { Container, Typography, Box} from '@mui/material';
import { styled } from '@mui/material/styles';
const Footer = ({ footer }) => {

  const Footer = styled('footer')(({ theme }) => ({
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  }));
  
  return (
    <Footer>
    <Container>
    {footer.length > 0 ? (
      footer.map(item => (
      <Box key={item.sys.id}>
          <Typography variant="body2">{item.fields.footerText}</Typography>
          {/* Render other footer fields */}
        </Box>
      ))
    ) : (
      <Typography>No footer content available.</Typography>
    )}
  </Container></Footer>
  );
};

export default Footer;
