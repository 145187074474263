import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginPage from './LoginPage';
import CartContent from './CartContent'; // Replace with your existing Cart content component

const Cart = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <>
        <CartContent />
    </>
  );
};

export default Cart;
