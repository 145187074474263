import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from './AnimatedBackButton';
import HomeButton from './AnimatedHomeButton';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LogoutIcon from '@mui/icons-material/ExitToApp'; // Icon for logout button
import { styled } from '@mui/material/styles';
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
  Grid,
  IconButton,
  Box,
} from '@mui/material';

const DeleteButton = styled(IconButton)(({ theme }) => ({
  top: theme.spacing(2),
  right: theme.spacing(3),
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}));

const CheckoutButton = styled(Button)(({ theme }) => ({
  top: theme.spacing(2),
  right: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  transition: 'background-color 0.3s ease',
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
  transition: 'background-color 0.3s ease',
}));

const ClearCartButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(10),
  right: theme.spacing(2),
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
  transition: 'background-color 0.3s ease',
}));

const CartContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);

  // Initialize cart state based on location state or localStorage
  useEffect(() => {
    const initialCart =
      location.state?.cart || JSON.parse(localStorage.getItem('cart')) || [];
    setCart(initialCart);
  }, [location.state]);

  const handleClearCart = () => {
    // Clear cart in localStorage
    localStorage.removeItem('cart');
    // Clear cart in state
    setCart([]);
  };

  const handleRemoveItem = (index) => {
    // Create a copy of the cart array
    const updatedCart = [...cart];
    // Remove the item at the specified index
    updatedCart.splice(index, 1);
    // Update cart state
    setCart(updatedCart);
    // Update localStorage
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };

  const handleLogout = () => {
    // Example: Perform logout actions here
    localStorage.removeItem('userToken'); // Clear user authentication token
    navigate('/login'); // Redirect to login page after logout
  };

  return (
    <Container>
      <BackButton />
      <HomeButton />
      <Typography variant="h4" align="center" gutterBottom>
        Your Cart
      </Typography>
      <Grid container spacing={3}>
        {cart.length > 0 ? (
          cart.map((service, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                <CardMedia
                  component="img"
                  image={`${service.fields.image.fields.file.url}?w=300&fit=fill`}
                  alt={service.fields.image.fields.title}
                  sx={{ width: 100, height: 100, objectFit: 'contain' }}
                />
                <CardContent sx={{ flex: 1, ml: 2 }}>
                  <Typography variant="h6">{service.fields.name}</Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Price: ${service.fields.price}
                  </Typography>
                  <DeleteButton aria-label="delete" onClick={() => handleRemoveItem(index)}>
                    <DeleteOutlineIcon />
                  </DeleteButton>
                <CheckoutButton
                  variant="contained"
                  color="primary"
                  onClick={() => alert('Checkout functionality not implemented')}
                >
                  Checkout
                </CheckoutButton>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Typography variant="h6" color="text.secondary">
                Your cart is empty.
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      {/* Logout button fixed to bottom right */}
      <LogoutButton variant="contained" onClick={handleLogout} startIcon={<LogoutIcon />}>
        Logout
      </LogoutButton>
      {/* Clear cart button fixed to bottom right, above logout button */}
      {cart.length > 0 && (
        <ClearCartButton
          variant="contained"
          onClick={handleClearCart}
          startIcon={<DeleteOutlineIcon />}
        >
          Remove All
        </ClearCartButton>
      )}
    </Container>
  );
};

export default CartContent;
