import React from 'react';
import { createClient } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import BackButton from './AnimatedBackButton'; 
import HomeButton from './AnimatedHomeButton'; 

const HtmlPage = ({ page }) => {
  console.log(page?.fields?.body)

  // Convert rich text JSON to HTML string
  const htmlContent = page?.fields?.body ? documentToHtmlString(page.fields.body) : '';

  return (
    <div>
      <BackButton />
      <HomeButton />
      <h1 style={{ textAlign: 'center' }}>{page?.fields?.name}</h1>
      <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default HtmlPage;
