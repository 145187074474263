import React, { useState } from 'react';
import { Box, Container, Typography, TextField, Button, Alert } from '@mui/material';
import BackButton from './AnimatedBackButton'; 

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name && email && message) {
      setSuccess(true);
      setError(false);
      setName('');
      setEmail('');
      setMessage('');
    } else {
      setError(true);
      setSuccess(false);
    }
  };

  return (
    <Container>
      <BackButton />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          display="flex"
          flexDirection="column"
          width="100%"
          maxWidth="500px"
        >
          {success && <Alert severity="success">Thank you for your message! We'll get back to you soon.</Alert>}
          {error && <Alert severity="error">Please fill out all fields.</Alert>}
          <TextField
            label="Name"
            variant="outlined"
            margin="normal"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Email"
            variant="outlined"
            margin="normal"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Message"
            variant="outlined"
            margin="normal"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Send Message
          </Button>
        </Box>
        <Box mt={4} textAlign="center">
          <Typography variant="h6">Our Contact Details</Typography>
          <Typography>Phone: +91 **********</Typography>
          <Typography>Email: echoconnectin@gmail.com</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default ContactUs;
