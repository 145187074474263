// LandingPageCarousel.js
import React from 'react';
import { Typography,Box, Paper, Button
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';

const Banner = ({ slide }) => (
    <Box>
      <Typography variant="h4">{slide.fields?.heading}</Typography>
      <Typography variant="body1">{slide.fields?.subHeading}</Typography>
      <img src={`${slide.fields.image.fields.file.url}`} alt={slide.fields.image.fields.title} style={{ maxWidth: '100%', height: 'auto' }} />
      {/* <Button variant="contained" color="primary">
        Learn More
      </Button> */}
    </Box>
  );
  
  const LandingPageCarousel = ({ landingPage }) => {
    const numSlides = landingPage?.length || 0;
  
    return (
      <>
        {numSlides === 1 ? (
          <Banner slide={landingPage[0]} />
        ) : (
        <Carousel>
        {landingPage?.map((slide, index) => (
          <Paper key={index}>
          {/* <Typography variant="h4">{slide.fields?.heading}</Typography>
          <Typography variant="body1">{slide.fields?.subHeading}</Typography> */}
          <Button variant="contained" color="primary">
            {slide.fields.heading}
          </Button>
          <img src={slide.fields.image.fields.file.url} alt={slide.fields.image.fields.title} style={{ maxWidth: '100%', height: 'auto' }} />
          </Paper>
        ))}
        </Carousel>
        )}
      </>
    );
  };


  export default LandingPageCarousel;
  