import React, { useState } from 'react';
import {
  AppBar, Toolbar, Container, Typography, CircularProgress, Box, 
  Grid ,InputBase, List, ListItem, ListItemText, Paper, Button, 
  IconButton, Card, CardContent, CardMedia, Dialog, DialogTitle, Menu, MenuItem,
  DialogContent, DialogActions, Slide, useMediaQuery, useTheme , BottomNavigation, BottomNavigationAction 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { StyledCard } from './StyledComponents.js'; // Create a StyledComponents.js for all styled components

const ServicePage = ({ services, header, subServices }) => {
  const [selectedService, setSelectedService] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  
  const handleServiceClick = (service) => {
    setSelectedService(service);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getResponsiveFontSize = () => {
    if (isMobile) return '14px';
    if (isTablet) return '16px';
    if (isDesktop) return '18px';
    return '16px';
  };

  const handleItemClick = (id, type) => {
    navigate('/service', { state: { id, type } });
  };

  return (
    <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{
          wordBreak: 'break-word',
        }}
      >
        {services?.servicesTitle}
      </Typography>

      <Grid container spacing={2} sx={{ paddingBottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TransitionGroup component={null}>
          {services?.services?.length > 0 ? (
            services?.services?.map(service => (
              <CSSTransition
                key={service.sys.id}
                timeout={300}
                classNames="fade"
              >
                <Grid item xs={4} sm={4} md={4}>
                  <StyledCard
                    onClick={() => handleServiceClick(service)}
                    color={header[0]?.fields?.color?.background || '#006f3c'}
                  >
                    <CardMedia
                      component="img"
                      height="140"
                      image={`${service.fields.image.fields.file.url}`}
                      alt={service.fields.image.fields.title}
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                    <CardContent sx={{ padding: '0px !important', backgroundColor : '#589b7a', color : 'white',display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{
                          fontSize: getResponsiveFontSize(),
                          wordBreak: 'break-word',
                          textAlign: 'center',
                          margin: 0,
                          height: '50px', // Set a fixed height for the title
                          display: 'flex',
                          alignItems: 'center', // Center vertically
                          fontFamily: 'cursive',
                        }}
                      >
                        {service.fields.name}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
              </CSSTransition>
            ))
          ) : (
            <Typography>No services available.</Typography>
          )}
        </TransitionGroup>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Slide}
        transitionDuration={500}
        TransitionProps={{
          direction: 'up',
        }}
        PaperProps={{
          style: {
            transform: isMobile ? 'translate(0%, 20%)' : 'translate(0%, 0%)',
            position: 'fixed',
            width: isMobile ? '100%' : '100%',
            height: '70%',
            borderRadius: isMobile ? '20px 20px 0 0' : '20px',
            scrollbarWidth: 'none',
          },
        }}
      >
        <Box position="relative">
          <DialogActions  style={{ position: 'absolute',right: '0'}}>
            <Button onClick={handleCloseDialog} variant="outlined" style={{ background: 'white'}}>X</Button>
          </DialogActions>
          {selectedService && (
            <>
              <CardMedia
                component="img"
                height="300"
                image={`${selectedService.fields.image.fields.file.url}?h=300&fit=fill`}
                alt={selectedService.fields.image.fields.title}
                sx={{
                  width: '100%',
                  objectFit: 'fill',
                  borderRadius: isMobile ? '20px 20px 0 0' : '20px 20px 0 0'
                }}
              />
              <Box sx={{ backgroundColor: '#006f3c', padding: '16px', color: '#fff' }}>
                <DialogTitle>{selectedService.fields.name}</DialogTitle>
                <DialogContent>
                  <Typography variant="body2" color="inherit">
                    {selectedService.fields.description}
                  </Typography>
                  <br />
                  <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {selectedService.fields.subServices?.length > 0 ? (
                      selectedService.fields.subServices?.map(subService => (
                        <Grid item key={subService.sys.id} xs={4} sm={4} md={4}>
                          {subServices
                            .filter(x => x?.sys?.id === subService?.sys?.id)
                            .map(x => (
                              <StyledCard
                                key={x.sys.id}  // Make sure to include a unique key for each mapped element
                                onClick={() => handleItemClick(x?.sys?.id, x?.sys?.contentType?.sys?.id)}
                                color={header[0]?.fields?.color?.background || '#006f3c'}
                              >
                                <Card sx={{ maxWidth: 300 }}>
                                  <CardMedia
                                    component="img"
                                    height="140"
                                    image={`${x.fields.image.fields.file.url}`}
                                    alt={x.fields.image.fields.title}
                                    sx={{
                                      width: '100%',
                                      height: '100%',
                                      objectFit: 'cover'
                                    }}
                                  />
                                  <CardContent sx={{ padding: '2px !important', backgroundColor : '#589b7a', color : 'white',display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      component="div"
                                      sx={{
                                        fontSize: getResponsiveFontSize(),
                                        wordBreak: 'break-word',
                                        textAlign: 'center',
                                        margin: 0,
                                        height: '50px', // Set a fixed height for the title
                                        display: 'flex',
                                        alignItems: 'center', // Center vertically
                                      }}
                                    >
                                      {x.fields.name}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </StyledCard>
                            ))}
                        </Grid>
                      ))
                    ) : (
                      <Typography variant="body2" color="inherit">No sub-services available.</Typography>
                    )}
                  </Grid>
                </DialogContent>
              </Box>
            </>
          )}
        </Box>
      </Dialog>
    </Box>
  );
};

export default ServicePage;
