import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, TextField, Button, Box } from '@mui/material';

const LoginPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    // Check if userToken exists in localStorage
    const isAuthenticated = localStorage.getItem('userToken');

    if (isAuthenticated) {
      // Redirect to cart page if userToken exists
      navigate('/cart');
    }
  }, [navigate]);
  const handleLogin = (e) => {
    e.preventDefault();
     if (username === 'd' && password === 'd') {
      // Simulate successful login
      localStorage.setItem('userToken', 'demo');
      // setIsLoggedIn(true); // Set isLoggedIn state to true (simulated login)
      navigate('/cart'); // Redirect to cart page after successful login
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Login Page
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        <TextField
          label="Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && (
          <Typography variant="body2" color="error" align="center" gutterBottom>
            {error}
          </Typography>
        )}
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Login
        </Button>
      </Box>
    </Container>
  );
};

export default LoginPage;
