import React from 'react';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const AnimatedButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderColor: '#006f3c', // Custom outline color
  color: '#006f3c', // Custom text color to match the outline
  transition: 'background-color 0.3s, transform 0.3s',
  '&:hover': {
    backgroundColor: '#006f3c',
    color: theme.palette.common.white,
    transform: 'scale(1.05)',
  },
  position: 'absolute', // Set the position to relative
}));

const BackButton = () => {
  const navigate = useNavigate(); // Move useNavigate inside the component

  const handleBack = () => {
    navigate(-1); // This will navigate back to the previous page
  };

  return (
    <AnimatedButton variant="outlined" onClick={handleBack} startIcon={<ArrowBackIcon />}>
      Back
    </AnimatedButton>
  );
};

export default BackButton;
