import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Container, Typography, CircularProgress, Box, useMediaQuery, useTheme, Button, Card, CardContent, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as contentful from 'contentful';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const client = contentful.createClient({
  space: 'ssltimlh2wol',
  accessToken: 'hWD2JgjsM3vzUkQH6yWxfommWBrACPpbtQ7D8Nh77ac'
});

const StyledCard = styled(Card)(({ theme, color }) => ({
  maxWidth: 300,
  position: 'relative',
  overflow: 'hidden',
  margin: theme.spacing(2),
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[5],
    '&::after': {
      width: '100%',
    },
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '0%',
    height: '4px',
    backgroundColor: color,
    transition: 'width 0.3s ease',
  },
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderColor: '#006f3c',
  color: '#006f3c',
  transition: 'background-color 0.3s, transform 0.3s',
  '&:hover': {
    backgroundColor: '#006f3c',
    color: theme.palette.common.white,
    transform: 'scale(1.05)',
  },
}));

const ServiceDetail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [service, setService] = useState(null);
  const [services, setServices] = useState([]);
  const [cart, setCart] = useState(() => JSON.parse(localStorage.getItem('cart')) || []);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { id, type } = location.state || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const fetchService = async () => {
      try {
        if (id) {
          const entry = await client.getEntry(id);
          setService(entry);
        } else {
          const entries = await client.getEntries({ content_type: 'service' });
          setServices(entries.items);
        }
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchService();
  }, [id]);

  const handleBack = () => {
    navigate(-1);
  };

  const getResponsiveFontSize = () => {
    if (isMobile) return '14px';
    if (isTablet) return '14px';
    if (isDesktop) return '18px';
    return '16px';
  };

  const handleItemClick = (id, type) => {
    navigate('/Service', { state: { id, type } });
  };

  const handleBookNow = (selectedService) => {
    console.log(selectedService)
    const updatedCart = [...cart, selectedService];
    setCart(updatedCart);
    try {
      localStorage.setItem('cart', JSON.stringify(updatedCart));
    } catch (error) {
      console.error(error.message); // "Converting circular structure to JSON"
    }
    navigate('/cart', { state: { cart: updatedCart } }); // Pass cart data to Cart component
  };
  

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography>Error loading service: {error.message}</Typography>
      </Box>
    );
  }

  return (
    <Container>
      <AnimatedButton variant="outlined" onClick={handleBack} startIcon={<ArrowBackIcon />}>
        Back
      </AnimatedButton>
      {id ? (
        service && (
          <>
            {type === 'service' && (
              <Box>
                <Typography variant="h4" gutterBottom>{service.fields.name}</Typography>
                <Typography variant="body1" gutterBottom>{service.fields.description}</Typography>
                {service.fields.image && (
                  <CardMedia
                    component="img"
                    image={`${service.fields.image.fields.file.url}?h=300&fit=fill`}
                    alt={service.fields.image.fields.title}
                    sx={{
                      width: '100%',
                      height: '300px',
                      objectFit: 'fill',
                      marginBottom: theme.spacing(2),
                    }}
                  />
                )}
                {service.fields.subServices && service.fields.subServices.length > 0 && (
                  <Grid container spacing={2} justifyContent="center">
                    {service.fields.subServices.map((x) => (
                      <Grid item xs={12} sm={6} md={4} key={x.sys.id}>
                        <StyledCard onClick={() => handleItemClick(x.sys.id, x.sys.contentType.sys.id)} color="#006f3c">
                          <CardMedia
                            component="img"
                            height="140"
                            image={x.fields.image.fields.file.url}
                            alt={x.fields.image.fields.title}
                            sx={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover'
                            }}
                          />
                          <CardContent sx={{ display: 'flex', backgroundColor: '#589b7a', color: 'white', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="div"
                              sx={{
                                fontSize: getResponsiveFontSize(),
                                wordBreak: 'break-word',
                                textAlign: 'center',
                                margin: 0,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {x.fields.name}
                            </Typography>
                          </CardContent>
                        </StyledCard>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Box>
            )}
            {type === 'subService' && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="h5" gutterBottom>{service?.fields?.name}</Typography>
                    <Typography variant="body1" gutterBottom>{service?.fields?.description}</Typography>
                    {service?.fields?.image && (
                      <CardMedia
                        component="img"
                        image={service.fields.image.fields.file.url}
                        alt={service.fields.image.fields.title}
                        sx={{
                          width: '100%',
                          height: '300px',
                          objectFit: 'fill',
                          marginBottom: theme.spacing(2),
                        }}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    {service?.fields?.price && (
                      <Typography variant="body1" gutterBottom>Price: ${service.fields.price}</Typography>
                    )}
                    {service?.fields?.timing && (
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <AccessTimeIcon sx={{ mr: 1 }} />
                        <Typography variant="body1" gutterBottom>Timing: {service.fields.timing}</Typography>
                      </Box>
                    )}
                    <Button variant="contained" color="primary" onClick={() => handleBookNow(service)}>{service?.fields?.button ? service?.fields?.button : "Book"}</Button>
                  </Box>
                </Grid>
              </Grid>
            )}
          </>
        )
      ) : (
        <Grid container justifyContent="center">
          {services.map((service) => (
            <Grid item xs={4} sm={4} md={4} key={service.sys.id}>
              <StyledCard onClick={() => handleItemClick(service.sys.id, 'service')} color="#006f3c">
                <CardMedia
                  component="img"
                  height="140"
                  image={service.fields.image.fields.file.url}
                  alt={service.fields.image.fields.title}
                  sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
                <CardContent sx={{ display: 'flex', backgroundColor: '#589b7a', color: 'white', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{
                      fontSize: getResponsiveFontSize(),
                      wordBreak: 'break-word',
                      height: '20px',
                      textAlign: 'center',
                      margin: 0,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {service.fields.name}
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default ServiceDetail;
