import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Link ,Navigate ,json, BrowserRouter } from 'react-router-dom';
import {
  AppBar, Toolbar, Container, Typography, CircularProgress, Box, 
  Grid ,InputBase, List, ListItem, ListItemText, Paper, Button, 
  IconButton, Card, CardContent, CardMedia, Dialog, DialogTitle, Menu, MenuItem,
  DialogContent, DialogActions, Slide, useMediaQuery, useTheme , BottomNavigation, BottomNavigationAction 
} from '@mui/material';
import { Home, History, Build,AccountCircle,ExpandMore } from '@mui/icons-material';
import { styled, alpha } from '@mui/material/styles';
import './Animations.css'; // Custom CSS for animations
import * as contentful from 'contentful';
import MenuIcon from '@mui/icons-material/Menu';
import ServiceDetail from './ServiceDetail';
import NotFound from './NotFound';
import ContactUs from './ContactUs';
import HtmlPage from './HtmlPage';

// Import the component
import Header from './compounds/Header';
import ServicePage from './compounds/ServicePage'; 
import LandingPageCarousel from './compounds/LandingPageCarousel'; 
import Footer from './compounds/Footer'; 
import Cart from './Cart';
import LoginPage from './LoginPage';

const defaultImage ='https://images.ctfassets.net/ssltimlh2wol/3Br1jpej72Xap9P9Bs6sPI/06e9934eb7972f95031adc026722ec88/Gemini_Generated_Image_5o24wf5o24wf5o24.jfif';
const client = contentful.createClient({
  space: 'ssltimlh2wol',
  accessToken: 'hWD2JgjsM3vzUkQH6yWxfommWBrACPpbtQ7D8Nh77ac'
});


const MainContent = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: 0, // Remove padding
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}));



const BottomNav = styled(BottomNavigation)(({ header }) => ({
  position: 'fixed', // Change position to static for desktop view
  width: '100%',
  bottom :0,
  zIndex: 1000,
  backgroundColor: header[0]?.fields?.color?.background || '#3f51b5',
  '& .MuiBottomNavigationAction-label, & .MuiSvgIcon-root': {
    color: '#ffffff',
  },
  '& .MuiBottomNavigationAction-root.Mui-selected .MuiSvgIcon-root': {
    color: '#023D54',
  },
}));


const App = () => {
  const [value, setValue] = React.useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [landingPage, setLandingPage] = useState([]);
  const [header, setHeader] = useState([]);
  const [footer, setFooter] = useState([]);
  const [services, setServices] = useState([]);
  const [subServices, setSubServices] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [location, setLocation] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setMobileMenuOpen(false); // Close the mobile menu after navigation
  };

  useEffect(() => {
    let isMounted = true;
    Promise.all([
      client.getEntries({ content_type: 'landingPage' }),
      client.getEntries({ content_type: 'header' }),
      client.getEntries({ content_type: 'footer' }),
      client.getEntries({ content_type: 'service' }),
      client.getEntries({ content_type: 'subService',
      limit: 1000,
      skip: 0 }),
    ]).then(([landingPageResponse, headerResponse, footerResponse, servicesResponse, subServicesResponse, pagesResponse]) => {
      if (isMounted) {
        setLandingPage(landingPageResponse.items);
        setHeader(headerResponse.items);
        setFooter(footerResponse.items);
        setServices(servicesResponse.items);
        setSubServices(subServicesResponse.items);
        setIsLoading(false);
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
      setIsLoading(false); // Stop loading even if there is an error
    });

    return () => {
      isMounted = false; // Cleanup function to prevent state updates after unmounting
    };
  }, []);

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query.length > 0) {
      const filteredServices = services?.filter(service =>
        service?.fields?.name?.toLowerCase().includes(query?.toLowerCase())
      );
      const filteredSubServices = subServices.filter(subService =>
        subService?.fields?.name?.toLowerCase().includes(query?.toLowerCase())
      );
      setFilteredResults([...filteredServices, ...filteredSubServices]);
    } else {
      setFilteredResults([]);
    }
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleItemClick = (id, type) => {   
    const query = '';
    setSearchQuery(query);   
    setFilteredResults([]);
    navigate('/service', { state: { id ,type} }); 
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }
  const isAuthenticated = localStorage.getItem('userToken');
  const handleProfileClick = () => {
    // Implement your logic here for handling profile click
    console.log('Profile clicked');
    // For example, navigate to profile page or show profile details
  };
  return (    
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Header
              headerData={header}
              searchQuery={searchQuery}
              handleSearchInputChange={handleSearchInputChange}
              location={location}
              handleLocationChange={handleLocationChange}
              filteredResults={filteredResults}
              handleItemClick={handleItemClick}
            />
  <MainContent>
  <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <ServicePage services={landingPage[0]?.fields} header={header} subServices={subServices} />
        </Grid>
        <Grid item xs={12} md={6}>
          <LandingPageCarousel landingPage={landingPage[0]?.fields?.carousel} />
        </Grid>
      </Grid>
    </Container>
</MainContent>
            <Footer footer={footer}/>
            {!isDesktop && (<><BottomNav display="flex" alignItems="center" flexGrow={1} header={header}
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              } }
              showLabels
            >
              <BottomNavigationAction label="Home" icon={<Home />} onClick={() => window.location.href = '/'} />
              <BottomNavigationAction label="Services" icon={<Build />} onClick={() => window.location.href = '/Service'} />
              {/* <BottomNavigationAction label="History" icon={<History />} onClick={() => window.location.href = '/history'} /> */}
             {(!isAuthenticated && (<BottomNavigationAction label="Login" icon={<AccountCircle />} onClick={() => window.location.href = '/login'} />)) ||<BottomNavigationAction label="Profile" icon={<AccountCircle />} onClick={() => handleProfileClick()} />}
              <BottomNavigationAction
                label="More"
                icon={<MenuIcon />}
                onClick={handleMobileMenuToggle} />
            </BottomNav>
            <Menu
              anchorEl={null}
              open={mobileMenuOpen}
              onClose={() => setMobileMenuOpen(false)}
              PaperProps={{
                style: {
                  position: 'absolute',
                  width: '100%',
                  // height:'80%', // Adjust as needed based on your menu width
                  bottom: '9%',
                  top: '0',
                },
              }}
            >
                {header[0].fields?.navigationLinks?.map((item, index) => (
                  <ListItem key={index} button onClick={() => handleNavigation(item.fields.name)}>
                    <ListItemText primary={item.fields.name} />
                  </ListItem>
                ))}
              </Menu></>
      )} 
          </>
        } />
         {header[0]?.fields?.navigationLinks?.map(page => (
      <Route
        key={page?.fields?.name}
        path={`/${page?.fields?.name}`}
        element={
        page?.fields?.type.toLowerCase() === 'service' ? 
        (<ServiceDetail/>) :
        (page?.fields?.type.toLowerCase() === 'contact' ? 
        (<ContactUs/>) :
        (<HtmlPage page={page} />))
      }
      />
    ))}
       <Route path="/Service" element={<ServiceDetail />} />
       {/* <Route path="/cart" element={<Cart />} /> */}
       <Route path="/cart" element={<PrivateRoute />} />
       <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
  );
};
const PrivateRoute = () => {
  // Check if user is authenticated
  const isAuthenticated = localStorage.getItem('userToken');

  return isAuthenticated ? <Cart /> : <Navigate to="/login" />;
};
export default App;
