import React, { useState } from 'react';
import { AppBar, Toolbar, Box, InputBase, Paper, List, ListItem, ListItemText, IconButton, Typography, useMediaQuery } from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocationOnIcon from '@mui/icons-material/LocationOn'; // Google location icon
import Autocomplete from 'react-google-autocomplete';

const Logo = styled('img')({
  height: 80,
  width: 80,
});

const SearchContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  marginLeft: theme.spacing(2),
  width: '100%', // Change to 100% to better fit mobile screens
  [theme.breakpoints.up('md')]: {
    width: '30%', // For larger screens, set width to 30%
  },
}));

const SearchResults = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  width: '100%',
  zIndex: 1,
  maxHeight: 300,
  overflowY: 'auto',
}));

const Header = ({ headerData, searchQuery, handleSearchInputChange, filteredResults, handleItemClick }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLocationSelectorOpen, setLocationSelectorOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setMobileMenuOpen(false); // Close the mobile menu after navigation
  };

  const handlePlaceSelect = (place) => {
    setSelectedLocation(place);
    setLocationSelectorOpen(false); // Close the location selector after selecting a place
  };

  const headerStyle = headerData.length > 0 ? {
    backgroundColor: headerData[0].fields.color?.background || '#3f51b5', // Default color if none provided
  } : {};

  return (
    <AppBar position="static" sx={headerStyle}>
      <Toolbar>
        <Box display="flex" alignItems="center" flexGrow={1}>
          {headerData.map(data => (
            <Box key={data.sys.id} display="flex" alignItems="center" width="100%">
              {data.fields.logo && (
                <Logo src={`${data.fields.logo.fields.file.url}?w=80&h=80`} alt={data.fields.logo.fields.title} />
              )}
              
          {/* Location selector */}
          <IconButton color="inherit">
          {/* <IconButton color="inherit" onClick={() => setLocationSelectorOpen(true)}> */}
            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
              <LocationOnIcon />
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                {selectedLocation ? selectedLocation.formatted_address : 'Chennai'}
              </Typography>
            </Box>
          </IconButton>
              <SearchContainer>
                <InputBase
                  placeholder="Search for services..."
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  sx={{
                    paddingLeft: '1em', // Adjust left padding as needed
                    fontSize: 'inherit', // Use the same font size as the input text
                  }}
                />
                {filteredResults.length > 0 && (
                  <SearchResults>
                    {filteredResults.map(result => (
                      <ListItem button key={result.sys.id} onClick={() => handleItemClick(result.sys.id, result.sys.contentType.sys.id)}>
                        <ListItemText primary={result.fields.name} />
                      </ListItem>
                    ))}
                  </SearchResults>
                )}
              </SearchContainer>
            </Box>
          ))}
        </Box>
        <Box display="flex" alignItems="center">
          {!isDesktop && (
            <List component="nav" sx={{ display: 'flex', flexDirection: 'row' }}>
              {headerData[0]?.fields?.navigationLinks?.map((item, index) => (
                <ListItem key={index} button onClick={() => handleNavigation(item.fields.name)}>
                  <ListItemText primary={item.fields.name} />
                </ListItem>
              ))}
            </List>
          )}
          {/* Cart button */}
          <IconButton color="inherit" onClick={() => handleNavigation('/cart')}>
            <ShoppingCartIcon />
          </IconButton>
        </Box>
      </Toolbar>
      {/* Location selector dropdown */}
      {isLocationSelectorOpen && (
        <Autocomplete
          apiKey="AIzaSyACx2_uxlMCYNYmDErC9-ZI4gTILSX31wA"
          onLoad={(autocomplete) => console.log('Autocomplete loaded:', autocomplete)}
          onPlaceSelected={(place) => handlePlaceSelect(place)}
          types={['(regions)']}
        />
      )}
    </AppBar>
  );
};

export default Header;
